/*global wp,pwsL10n,jsPDF, html2canvas */
import { Alert, Button, Carousel, Collapse, Dropdown, Modal, Offcanvas, Popover, ScrollSpy, Tab, Toast, Tooltip } from 'bootstrap'; //quiet
import {Datepicker} from 'vanillajs-datepicker'; //quiet
import n4dPassword from './password';

const bootstrap = {
	Alert,
	Button,
	Carousel,
	Collapse,
	Dropdown,
	Modal,
	Offcanvas,
	Popover,
	ScrollSpy,
	Tab,
	Toast,
	Tooltip
};

class n4d {
	constructor() {
		this.body = document.querySelector('body');
		this.body.classList.add('loading');
	}
	checkPasswordStrength (){
		const input_id     = this.pwd.id;
		let form_id        = "";
		const botcheck     = document.querySelector('#bot-check');


		let disallowedList = n4dPassword.userInputDisallowedList();

		let pass1          = this.pwd.value;
		let pass2          = (this.retyped !== null) ? this.retyped.value : false;
		let strength       = n4dPassword.meter( pass1, disallowedList, pass2 );

		this.forms.forEach((form) => {
			let parentForm = form.querySelector("#"+input_id);

			if (parentForm) form_id = form.id;
			if (form_id){
				const submit = document.querySelector("#"+form_id+" input[type=submit]");
console.log(form_id, submit);

				if (submit) submit.disabled = true;
				let meter = document.querySelector("#"+form_id+" #password-strength");
				if (meter) meter.classList.remove( 'short', 'bad', 'good', 'strong' );

				switch ( strength ) {
					case 2:
						meter.classList.add( 'bad' );
						meter.innerHTML = "Weak";//pwsL10n.bad;
					break;

					case 3:
						meter.classList.add( 'good' );
						meter.innerHTML = "Medium";//pwsL10n.good;
					break;

					case 4:
						meter.classList.add( 'strong' );
						meter.innerHTML = "Strong";//pwsL10n.strong;
					break;

					case 5:
						meter.classList.add( 'short' );
						meter.innerHTML = "Mismatch";//pwsL10n.mismatch;
					break;

					default:
						meter.classList.add( 'short' );
						meter.innerHTML = "Very Weak";//pwsL10n.short;
					break;
				}

				let fg_pwd = this.pwd.closest(".row");
				let fg_rt  = this.retyped.closest(".row");

				if (strength <= 2){
					fg_pwd.classList.add("has-error");
					fg_pwd.classList.remove("has-success");
					this.pwd.setCustomValidity("Invalid field.");
				}

				if (strength <= 4 && strength > 1 && strength < 5){
					fg_pwd.classList.remove("has-error");
					fg_pwd.classList.add("has-success");

					this.pwd.setCustomValidity("");

					if ('' !== pass2.trim() && submit){
						submit.disabled = false;
						botcheck.value = 1;
					}
				}
				if (strength === 5 || '' === pass2.trim()){
					fg_rt.classList.add("has-error");
					if (submit) submit.disabled = true;
					this.retyped.setCustomValidity("Invalid field.");
				} else {
					fg_rt.classList.remove("has-error");
					this.retyped.setCustomValidity("");
				}


			}
		});

		return strength;
	}
	getAge(date){
		let now      = new Date();
		let yearNow  = now.getYear();
		let monthNow = now.getMonth();
		let dateNow  = now.getDate();

		let dob = new Date(
			date.substring(6,10),
			date.substring(3,5)-1,
			date.substring(0,2)
		);

		let yearDob     = dob.getYear();
		let monthDob    = dob.getMonth();
		let dateDob     = dob.getDate();
		let yearAge     = yearNow - yearDob;
		let monthAge    = "";
		let dateAge     = "";

		if (monthNow >= monthDob){
			monthAge = monthNow - monthDob;
		}
		else {
			yearAge--;
			monthAge = 12 + monthNow -monthDob;
		}

		if (dateNow >= dateDob){
			dateAge = dateNow - dateDob;
		}
		else {
			monthAge--;
			dateAge = 31 + dateNow - dateDob;

			if (monthAge < 0) {
				monthAge = 11;
				yearAge--;
			}
		}

		return {
			years: yearAge,
			months: monthAge,
			days: dateAge
		};


	}
	countCharacters(value){
		let words   = value;
		let count   = 0;

		count += (words.match(/[ก-๚]/g) || []).length;
		count += (words.match(/[0-9]/g) || []).length;
		count += (words.match(/[a-zA-Z]/g) || []).length;
		count += (words.match(/\s/g) || []).length;

		return count;
	}
	setupForms(){
//Validate Forms
// Loop over them and prevent submission
		Array.prototype.slice.call(this.forms).forEach((form) => {
			form.addEventListener('submit', event => {
				if (!form.checkValidity()) {
					event.preventDefault();
					event.stopPropagation();
				}
				let ranks    = document.querySelectorAll(".rank-select");
				let rank_err = false;
				ranks.forEach(input => {
					if (input.value == "") {
						event.preventDefault();
						event.stopPropagation();
						input.setAttribute("required", "required");
						input.required = true;

					}
				});

				form.classList.add('was-validated');
			}, false)
		});
		document.querySelectorAll('.btn-form-next').forEach((element) => {
			element.addEventListener("click", (e) => {
				let id        = e.target.dataset.id;
				let save      = document.querySelector(".btn-validate");
				let inputs    = document.querySelectorAll(".form-control,.form-select");

				inputs.forEach(input => {
					input.setAttribute("required", "");
					input.required = false;
				});

				document.querySelector(id + ' input[name="n4d_form_completed"]').value = 1;
				document.querySelector(id + ' input[name="n4d_form_next"]').value = 1;

				save.click();
			});
		});
		document.querySelectorAll('.btn-save').forEach((element, index) => {
			element.addEventListener("click", (e) => {
				let id        = e.target.dataset.id;
				let inputs    = document.querySelectorAll(".form-control,.form-select");
				inputs.forEach(input => {
					input.setAttribute("required", "");
					input.required = false;
				});


				let save      = document.querySelector(id + " .btn-validate");
				save.click();
			});
		});
		document.querySelectorAll('input[type=file]').forEach((element) => {
			element.addEventListener("change", () => {
				var filename = element.value.split('\\').pop();
				document.querySelector("#for-"+element.id).innerHTML = filename.replace(/C:\\fakepath\\/i, '');

				var id = `#preview-${element.id}-note`;
				let note = document.querySelector(`#preview-${element.id}-note`);
				if (note){
					note.classList.remove('active');
				}

			});
		});
		document.querySelectorAll('.remove-file').forEach((element) => {
			element.addEventListener("click", () => {
				let target  = element.dataset.target;
				let slug    = target.replace("#", "");
				let label   = document.querySelector("#for-"+slug);

				let pid     = document.querySelector('input[name=n4d_form_record]').value;

				let message = label.dataset.message;
				if (message)  label.innerHTML = message

				fetch('/wp-json/n4d/v1/reset/'+pid+'/'+slug, {
					method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
					mode: 'cors', // no-cors, *cors, same-origin
					cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
					credentials: 'same-origin', // include, *same-origin, omit
					headers: {
					  'Content-Type': 'application/json',
					  'X-WP-Nonce': wp_ajax_object.nonce
					}
				})
				.then(response => {
				});
				document.querySelector(target+"-info").remove();

			});
		});
//ALERT
		document.querySelectorAll(".form-alert").forEach((element, index) => {
			element.classList.add("active");
			setTimeout(() => {
				element.classList.remove("active");
				setTimeout(() => {
					element.remove();
				}, 500);
			}, 2000);
		});
//WORD COUNTER
		document.querySelectorAll(".word-counter").forEach((element, index) => {
			const limit      = element.dataset.length;
			const name       = element.name;
			const label      = document.querySelector("label[for="+name+"]");
			const label_text = label.innerHTML;
			let count        = element.value.split(" ").length;//this.countCharacters(element.value);

			label.classList.add("has-counter");
			label.innerHTML = label_text+`<div id="word-counter-${name}" class="word-counter"><span>${count}</span>/${limit}</div>`;


			element.addEventListener("keyup", e => {
				const name       = element.name;
				let count        = element.value.split(" ").length;//this.countCharacters(element.value);

				if (count > limit) {
					count = limit;
					let words = element.value.split(" ");

					element.value = words.reduce( (total, value, index) => {
						let space = (index == (limit - 1)) ? '' : ' ';
						return (index < limit) ? total + `${value}${space}`  : total;
					}, "");
				}


				document.querySelector("#word-counter-"+name+" span").innerHTML = count;



			});
		});
//PROVINCE FILLER
		document.querySelectorAll(".select_province").forEach((element, index) => {
			let district          = document.querySelector("."+element.getAttribute("name")+"_district");
			let district_value    = district.dataset.selected;
			let subdistrict       = document.querySelector("."+element.getAttribute("name")+"_subdistrict");
			let subdistrict_value = subdistrict.dataset.selected;
			let postcode          = document.querySelector("."+element.getAttribute("name")+"_postcode");
			let postcode_value    = postcode.value;
			let first             = true;

			element.addEventListener("change", e => {
				element.dataset.selected = e.target.value;

				if (district){
					fetch('/wp-json/n4d/v1/district/'+e.target.value)
					.then(response => response.json())
					.then(data => {
						let options = data.data.map(item => {
							let selected = (district_value == item['id']) ? ' selected="selected"': '';
							return `<option value="${item['id']}"${selected}>${item['name_th']}</option>`;
						});
						district.innerHTML = options.join("");
						if (district_value) district.dispatchEvent(new Event('change'));
						district_value = e.target.value;
					});

				}
			});
			if (district){
				district.addEventListener("change", e => {
					district.dataset.selected = e.target.value;
					if (subdistrict){
						fetch('/wp-json/n4d/v1/subdistrict/'+e.target.value)
						.then(response => response.json())
						.then(data => {
							let options = data.data.map(item => {
								let selected = (subdistrict_value == item['id']) ? ' selected="selected"': '';
								return `<option value="${item['id']}"${selected}>${item['name_th']}</option>`;
							});
							let results = options.length;
							subdistrict.innerHTML = options.join("");
							if ((subdistrict_value && (postcode_value == "" || postcode_value == "0")) || results == 1 || first !== true) subdistrict.dispatchEvent(new Event('change'));
							first = false;
						});
					}
				});
			}
			if (subdistrict){
				subdistrict.addEventListener("change", e => {
					subdistrict.dataset.selected = e.target.value;

					fetch('/wp-json/n4d/v1/postcode/'+e.target.value)
					.then(response => response.json())
					.then(data => {if (postcode) postcode.value = data.data});

				});
			}
			element.dispatchEvent(new Event('change'));
		});
//Datepickers
		this.datepickers = [];
		document.querySelectorAll('.datepicker-trigger').forEach((element, index) => {
			let start_date = (element.dataset.start !== undefined) ? element.dataset.start : "01/01/1970";

			this.datepickers[index] =  new Datepicker(element, {
				buttonClass: 'btn',
				defaultViewDate: start_date,
				format: 'dd/mm/yyyy'
			});

			if (element.classList.contains("has-age")){
				let id     = element.id;
				let data   = this.getAge(element.value);

				if (element.value == ""){
					data.years  = 0;
					data.months = 0;
				}
				let age    = document.querySelector("#"+id+"-years");
				if (age) age.innerHTML = `<span class="value">${data.years}</span><small>Years</small>`;

				let month  = document.querySelector("#"+id+"-months");
				if (month) month.innerHTML = `<span class="value">${data.months}</span><small>Months</small>`;

				element.addEventListener('changeDate', (event) => {
					let id     = event.target.id;
					let data   = this.getAge(event.target.value);
					let age    = document.querySelector("#"+id+"-years");
					let month  = document.querySelector("#"+id+"-months");
					if (age) age.innerHTML = `<span class="value">${data.years}</span><small>Years</small>`;
					if (month) month.innerHTML = `<span class="value">${data.months}</span><small>Months</small>`;
				});
			}
		});
//Use Password Strength Meter
		this.pwd = document.querySelector('input.strength');
		if (this.pwd) {
			this.pwd.addEventListener("keyup", this.checkPasswordStrength.bind(this));
			this.retyped = document.querySelector(this.pwd.dataset.linked);
			if (this.retyped) this.retyped.addEventListener("keyup", this.checkPasswordStrength.bind(this));
		}
//REPEAT GROUP
		document.querySelectorAll('.btn-repeat').forEach((element, index) => {
			element.addEventListener("click", e => {
				let slug  = e.target.dataset.target;
				let index = parseFloat(e.target.dataset.index) + 1;
				let target = jQuery(slug+"-"+index);
				if (target){
					target.collapse("show");

					e.target.dataset.index++;
				} else {
					e.target.remove();
				}
			});
		});
		document.querySelectorAll('.rank-select').forEach((element, index) => {

			element.addEventListener("change", e => {
				let value  = element.value;
				let parent = element.dataset.parent;
				let block  = element.dataset.block;
				let me     = element.getAttribute("name");

				document.querySelectorAll( ".rank-select[data-parent='"+parent+"']" ).forEach((el, i) => {
					if (value === el.value && el.getAttribute("name") !== me){
						el.value = "";
					}
				});

				document.querySelectorAll( ".rank-select[data-block='"+block+"']" ).forEach((el, i) => {
					if (value === el.value && el.getAttribute("name") !== me){
						el.value = "";
					}
				});
			});

		});
	}
	init(){
//Show Page
		this.body.classList.remove('loading');
//Setup Forms
		this.forms = document.querySelectorAll('.needs-validation');
		if (this.forms.length > 0) this.setupForms();

		this.print = document.querySelectorAll('.app-print');
		if (this.print.length > 0){
			this.print.forEach(element => {
				element.addEventListener("click", () => {
					window.print();
				});
			});
		}
		this.pdf = document.querySelectorAll('.app-pdf');
		if (this.pdf.length > 0){
			const pdf_holder = document.querySelector("#pdf-holder");
			let filename = pdf_holder.dataset.name;

			this.pdf.forEach(element => {
				element.addEventListener("click", (e) => {
					let target = element.dataset.id;
					const paper = document.querySelector(target);
					this.body.classList.add("pdf-mode");

					html2canvas( paper, {
						"scale": 2
					}).then(canvas =>  {
						pdf_holder.src = canvas.toDataURL("image/jpeg", 0.8);

						pdf_holder.onload = () => {
							console.log("loaded");

							pdf_holder.style.width = "1000px";

							let file = new jsPDF({
								orientation: 'p',
								unit: 'px',
								format: [1000, pdf_holder.offsetHeight],
								putOnlyUsedFonts:true,
								floatPrecision: 16 // or "smart", default is 16
							});
							file.addImage(pdf_holder, 'JPEG', 0,0, pdf_holder.offsetWidth, pdf_holder.offsetHeight);
							file.save(filename+".pdf");

							this.body.classList.remove("pdf-mode");
							pdf_holder.src = "";
						};
					}).catch(() => {
						console.log("error");
					});
				});
			});
		}

		//Enabled Collapse Tree
		document.querySelectorAll('.collapse-trigger').forEach(function(element){
			let name = element.getAttribute("name");
			let target = element.dataset.target;
			let collapseTriggerLinks = document.querySelectorAll('input[name="'+name+'"],select[name="'+name+'"]');


			collapseTriggerLinks.forEach(link => {
				link.dataset.target = target;
				link.addEventListener("change", function(){
					let subtarget = "";
					const element = document.querySelector(this.dataset.target);

					let target    = bootstrap.Collapse.getOrCreateInstance( element, {"toggle": false});
					let toggle    = ( this.checked && this.classList.contains("collapse-trigger")) ? "show" : "hide";

					if (this.checked && this.classList.contains("trigger-hide")) toggle = "hide";
					if (this.checked && this.classList.contains("trigger-show")) toggle = "show";

					if (link.tagName == "SELECT"){
						let otherValue = link.options[link.options.length - 1].value;
						toggle = (link.value == otherValue) ? "show" : toggle;
					}

					target[toggle]();

					let subtoggle = ( this.checked && this.classList.contains("collapse-trigger-sub") ) ? "show" : "hide";
					if (subtoggle && this.dataset.subtarget !== undefined){
						subtarget = bootstrap.Collapse.getOrCreateInstance( document.querySelector(this.dataset.subtarget), {"toggle": false});

						if ( this.getAttribute("type") == "radio" || ( this.getAttribute("type") == "checkbox"  && this.classList.contains("collapse-trigger-sub") ) ) {
							subtarget[subtoggle]();

							document.querySelectorAll(this.dataset.subtarget + " .is-required").forEach(function(el){
								el.setAttribute("required", "");
								el.required = (subtoggle == "show") ? true : false;
							});
						} else {
							subtarget[subtoggle]();
						}
					}
					else {
						collapseTriggerLinks.forEach(function(el){
							if (el.classList.contains("collapse-trigger-sub") && !el.checked){
								subtarget = bootstrap.Collapse.getOrCreateInstance( document.querySelector(el.dataset.subtarget), {"toggle": false});
								if (subtarget) subtarget.hide();
								document.querySelectorAll(el.dataset.subtarget + " .is-required").forEach(function(el){
									el.setAttribute("required", "");
									el.required = (subtoggle == "show") ? true : false;
								});
							}
						});
					}



					document.querySelectorAll(this.dataset.target + " .is-required").forEach(function(el){
						el.setAttribute("required", "");
						el.required = (toggle == "show") ? true : false;
					});

				});

				if (link.classList.contains("trigger-show")) link.dispatchEvent(new Event('change'));

				let toggle    = ( element.checked ) ? "show" : "hide";
				if (element.tagName == "SELECT"){
					let otherValue = link.options[link.options.length - 1].value;
					toggle = (element.value == otherValue) ? "show" : toggle;
				}
				document.querySelectorAll(target + " .is-required").forEach(el => {
					el.setAttribute("required", "");
					el.required = (toggle == "show") ? true : false;
				});

			});
			let collapse = document.querySelector(target);


			if ( collapse && !collapse.classList.contains("show") ){
				collapse.querySelectorAll(".form-control,.form-select").forEach(el => {
					if (el.getAttribute("required") !== null){
						el.setAttribute("required", "");
						el.required = false;
						el.classList.add("is-required");
					}
				});
			}

		});

	}
}
//Create Site APP
let app = new n4d();
//CHECK READY STATE
document.onreadystatechange = () => {
	if (document.readyState === 'complete') app.init();
};
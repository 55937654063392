/*global zxcvbn */

export default class n4dPassword {
	constructor() {}
	static meter( password1, disallowedList, password2 ) {
		if ( ! Array.isArray( disallowedList ) )
			disallowedList = [ disallowedList.toString() ];

		if (password1 != password2 && password2 && password2.length > 0)
			return 5;

		if ( 'undefined' === typeof window.zxcvbn ) {
			// Password strength unknown.
			return -1;
		}

		var result = zxcvbn( password1, disallowedList );
		return result.score;
	}
	static userInputDisallowedList() {
		var i, userInputFieldsLength, rawValuesLength, currentField,
			rawValues       = [],
			disallowedList  = [],
			userInputFields = [ 'user_login', 'first_name', 'last_name', 'nickname', 'display_name', 'email', 'url', 'description', 'weblog_title', 'admin_email' ];

		// Collect all the strings we want to disallow.
		rawValues.push( document.title );
		rawValues.push( document.URL );

		userInputFieldsLength = userInputFields.length;
		for ( i = 0; i < userInputFieldsLength; i++ ) {
			currentField = document.querySelector( '#' + userInputFields[ i ] );//$( '#' + userInputFields[ i ] );

			if ( null === currentField ) continue;

			rawValues.push( currentField.value );
		}
		/*
		 * Strip out non-alphanumeric characters and convert each word to an
		 * individual entry.
		 */
		rawValuesLength = rawValues.length;
		for ( i = 0; i < rawValuesLength; i++ ) {
			if ( rawValues[ i ] ) {
				disallowedList = disallowedList.concat( rawValues[ i ].replace( /\W/g, ' ' ).split( ' ' ) );
			}
		}
		/*
		 * Remove empty values, short words and duplicates. Short words are likely to
		 * cause many false positives.
		 */
		disallowedList = disallowedList.filter((item) => {
			return (item === '' || item.length < 4) ? false : item;
		});

		return disallowedList;
	}
}